import { TerminosYCondicionesService } from './_core/modules/terminos-y-condiciones/services/terminos-y-condiciones.service';
import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { UtilitiesService } from './_core/services/common/utilities/utilities.service';
import { FirebaseMessagingService } from './_core/services/firebase-messaging/firebase-messaging.service';
import { UserDataService } from './_core/authentication/user-data.service';
import { MatIconRegistry } from '@angular/material/icon';
import { NotificationsService } from './_shared/modules/agd-components/notifications/services/notifications/notifications.service';
import { Notification } from './_shared/modules/agd-components/notifications/models/notification.model';
import { routeDictionary } from './_core/dictionary/routes.dictionary';
import { OfflineService } from './_modules/offline/services/offline.service';
import { GoogeTagService } from './_core/services/google-tag-manager/googe-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showModal = false;

  notification: Notification;

  /**
   *  Service Title used to change the document title setted in app.routing.module
   */
  constructor(
    titleService: Title,
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    private readonly firebaseService: FirebaseMessagingService,
    private readonly userDataService: UserDataService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly notificationsService: NotificationsService,
    private readonly offlineService: OfflineService,
    private readonly googleTagService: GoogeTagService,
    private readonly tycService: TerminosYCondicionesService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let title = '';
        const dataTitle = this.getTitleWithSubTitle(router.routerState, router.routerState.root);
        if (dataTitle.length > 1) {
          dataTitle.splice(0, 1);
          title = dataTitle.join(' - ');
        } else {
          [title] = dataTitle;
        }

        titleService.setTitle(`${title} | AGD AGRO`);

        // Enviar evento de navegacion actual del usuario a Google Tag Manager.
        this.googleTagService.sendPaginationEvent(event.urlAfterRedirects);
      }
    });

    // we add custom svg icons.
    const customIcons = [
      'reddy_icon_blanco',
      'reddy_icon_gris',
      'agripago',
      'granos_icon',
      'precios_icon',
      'ventas_icon',
      'pagos_icon',
      'insumos_icon',
      'alert_icon',
      'experta_icon',
      'logo_agd',
      'iso_agd',
      'bajadeusuario_paso1',
      'bajadeusuario_paso2',
      'bajadeusuario_paso3',
      'bank_rounded',
      'bank_check',
      'maiz_icono',
      'girasol_icono',
      'soja_icono',
      'sorgo_icono',
      'trigo_icono',
      'venta_de_granos_icon',
      'down_arrow',
      'globo_icono',
      'marketing_icon',
      'mesa_de_ayuda_icon',
      'default_avatar',
      'reddy_color_icon',
      'done',
      'rejected',
    ];

    customIcons.forEach(this.registerSVGIcon.bind(this));

    // Init offline behavior
    this.offlineService.setOfflineBehavior();
    this.offlineService.check();
  }

  /**
 * Register last click event with the UtilitiesService.
 * @param event event
 */

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.utilitiesService.documentClickedEvent.next(event);
  }

  registerSVGIcon(filename: string) {
    this.matIconRegistry.addSvgIcon(
      filename,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/custom/${filename}.svg`)
    );
  }

  /**
   * Collect that title data properties from all child routes
   */
  getTitleWithSubTitle(state, parent) {
    const data = [];
    if (parent && state) {
      if (parent.snapshot.data?.title) {
        data.push(parent.snapshot.data.title);

        if (parent.snapshot.data.subTitle) {
          data.push(parent.snapshot.data.subTitle);
        }
      }

      data.push(...this.getTitleWithSubTitle(state, state.firstChild(parent)));
    }

    return data;
  }

  async ngOnInit() {
    // Suscribir comportamiento para notificaciones push
    this.firebaseService.onNewFirebaseMessage().subscribe({
      next: (res: any) => {
        if (res?.notification && res?.data) {
          const data = new Notification({
            titulo: res.notification.title,
            mensaje: res.notification.body,
            ...res.data
          });

          this.userDataService.onPushNotification(data);
        }
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    this.notificationsService.showModalSubject.subscribe({
      next: (res: any) => {
        this.notification = res;

        // if the modal is already showing, we set a timer to detect the changes
        if (this.showModal) {
          this.showModal = false;
          setTimeout(() => {
            this.showModal = true;
          });
        } else {
          this.showModal = true;
        }
      },
      error: err => console.error(err)
    });

    if (this.userDataService.userIsLoggedIn()) {
      this.firebaseService.suscribeToMessages();
      // If user is logged in we look for user data.
      const userData = await this.userDataService.getUserData();

      const { href } = window.location;

      let defaultCuit: string;

      // Change the active cuit if set by param
      if (href.includes('cuit=') && userData.cuits) {
        const userCuit = userData.cuits.find(cuit => href.includes(cuit.cuit));
        // set active quit
        defaultCuit = userCuit?.cuit;
      }

      // deepcode ignore Ssrf: the "CUIT" format is validated on the backend side, "WAF" is also available
      this.userDataService.loadCuitsData(defaultCuit);
    }
  }

  closeModal(event) {
    this.showModal = false;

    const {
      modulo_id, grilla_id, modal, url
    } = this.notification;

    if (event === 'click' && modal?.web?.accion === 'navegar') {
      if (modulo_id) {
        this.router.navigateByUrl(routeDictionary[modulo_id][grilla_id]);
      } else if (url) {
        this.notificationsService.showLinkConfirmationModal(url);
      }
    }
  }
}
