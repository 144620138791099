import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogService } from './confirm-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
  ],
  imports: [
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [
    ConfirmDialogService,
  ]
})
export class ConfirmDialogModule { }
