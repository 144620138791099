import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmDialogModule } from 'src/app/_shared/modules/agd-components/confirm-dialog/confirm-dialog.module';
import { InfoBoxComponent } from 'src/app/_shared/modules/agd-components/info-box/info-box.component';
import { TycModalComponent } from './components/tyc-modal/tyc-modal.component';
import { TerminosYCondicionesService } from './services/terminos-y-condiciones.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    TycModalComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    InfoBoxComponent,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ConfirmDialogModule,
    MatDialogModule,
  ],
  providers: [
    TerminosYCondicionesService,
  ]
})
export class TerminosYCondicionesModule { }
