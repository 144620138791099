import { AyudaModule } from './_shared/modules/agd-components/ayuda/ayuda.module';
import { ReclamosModule } from './_modules/pages/modules/reclamos/reclamos.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, CustomPreloadingStrategy } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './_core/core.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFormat } from './_shared/helpers/dates';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SharedModule } from './_shared/shared.module';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { firebaseConfig } from 'src/config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineModule } from './_modules/offline/offline.module';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { TerminosYCondicionesModule } from './_core/modules/terminos-y-condiciones/terminos-y-condiciones.module';

registerLocaleData(localeEsAr, 'es-Ar');

const formFieldOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'dynamic',
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReclamosModule,
    AyudaModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OfflineModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
    TerminosYCondicionesModule,
  ],
  exports: [
    AppComponent
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideMessaging(() => getMessaging()),
    {
      provide: DateAdapter,
      useClass: DateFormat
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: formFieldOptions
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        hideSingleSelectionIndicator: true,
      }
    },
    CustomPreloadingStrategy,
    { provide: LOCALE_ID, useValue: 'es-Ar' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
